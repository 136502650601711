import { scroller } from 'react-scroll'

// Offset objects leveraged in scrolling utilities
const offsets = {
    none: {
        desktop: 0,
        tablet: 0,
        mobile: 0
    },
    header: {
        desktop: -70,
        tablet: -56,
        mobile: -47
    }
}

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 1025,
    xl: 1200
}

// determine if the element is either above or below the viewport
const isInViewport = (el, offset = 0) => {
    const { top, bottom } = el.getBoundingClientRect()
    const vHeight = (window.innerHeight || document.documentElement.clientHeight) + offset

    return (
        (top > 0 || bottom > 0) &&
        top < vHeight
    )
}

const getDocumentOffset = (el) => {
    let elementToCheck = el
    let distance = 0

    // travel up DOM to get distance between child and parents to get total offset from top of document
    if (elementToCheck.offsetParent) {
        do {
            distance += elementToCheck.offsetTop
            elementToCheck = elementToCheck.offsetParent
        } while (elementToCheck)
    }
    return distance < 0 ? 0 : distance
}

const getBreakpointName = () => {
    if (typeof window === 'undefined') {
        throw new Error('Cannot retrieve window object - position.getOffsetKey should only be called after the DOM is rendered')
    } else if (window.innerWidth < breakpoints.sm) {
        return 'mobile'
    } else if (window.innerWidth < breakpoints.lg) {
        return 'tablet'
    } else {
        return 'desktop'
    }
}

const _scrollToElement = (elementId, elementToFocus, offset, duration = 500) => {
    // if there is a button child under the element scrolled to, shift users focus to that button
    if (elementToFocus) {
        elementToFocus.focus()
    }

    scroller.scrollTo(elementId, {
        duration,
        smooth: 'easeInOutQuad',
        offset
    })
}

// Scrolls an element to the top of the viewport.
// Defaults to header offset, but can be overridden with a custom offset object
const scrollToElement = (elementId, responsiveOffsets = offsets.header, duration) => {
    if (!document.getElementById(elementId)) return null

    const breakpointName = getBreakpointName()
    const offset = responsiveOffsets[breakpointName]
    // grab the element we're scrolling to and get the nearest button child
    const elementToFocus = document.getElementById(elementId).querySelector('button')

    // Mobile layout offsets are inconsistent across pages, seemingly due to timing of elements moving after initial render
    // `setTimeout` resolves the issue by tweaking the placement after things settle
    breakpointName === 'desktop'
        ? _scrollToElement(elementId, elementToFocus, offset, duration)
        : setTimeout(() => { _scrollToElement(elementId, elementToFocus, offset, duration) }, 500)
}

export {
    isInViewport,
    getDocumentOffset,
    scrollToElement
}
